import { View, StyleSheet } from "@/ui";
import { Colors } from "@/constants";
import PrimaryButton from "@/ui/PrimaryButton";
import Logo from "@/ui/Logo";
import { useRouter } from "next/router";

const Header = () => {
  return (
    <View style={s.headerContainer}>
      <Logo />

      <View style={{ flexDirection: "row", gap: 12 }}>
        <PrimaryButton
          style={{ backgroundColor: Colors.gray3 }}
          textStyle={{ color: Colors.text }}
          text="Login"
          onPress={() => {}}
        />
        <PrimaryButton
          style={{ backgroundColor: Colors.text }}
          text="Upgrade"
          onPress={() => {}}
        />
      </View>
    </View>
  );
};

Header.HEIGHT = 108;

const s = StyleSheet.create({
  headerContainer: {
    marginHorizontal: 24,
    flexDirection: "row",
    justifyContent: "space-between",
    height: Header.HEIGHT,
    alignItems: "center",
  },
});
export default Header;
