import { useEffect } from "react";
import { View, Text, StyleSheet } from "@/ui";
import { Instrument_Serif } from "next/font/google";
import { Colors, SUPPORTED_FILE_EXTENSIONS } from "@/constants";
import PrimaryButton from "@/ui/PrimaryButton";
import { useUISlice } from "@/models/useUISlice";
import { useUserSlice } from "@/models/useUserSlice";
import { datadogLogs } from "@datadog/browser-logs";
import { t } from "i18next";
import { useFilePicker } from "use-file-picker";
import Header from "@/ui/Header";

const instrumentSerif = Instrument_Serif({
  subsets: ["latin"],
  style: ["normal", "italic"],
  weight: ["400"],
});

const FeatureItem = ({ number, children }) => {
  return (
    <View style={s.featureItem}>
      <Text
        style={{
          color: Colors.subtitle,
          fontSize: 16,
          fontWeight: "300",
          marginTop: 2,
        }}
      >
        {number}
      </Text>
      {children}
    </View>
  );
};

const _onLandingPageContentPress = (
  type: "recordings" | "high-quality" | "delete"
) => {
  console.log("landing page content", type);
};

const LandingPage = () => {
  const isMobile = useUISlice((state) => state.isMobile);
  const { openFilePicker, plainFiles } = useFilePicker({
    multiple: false,
    accept: SUPPORTED_FILE_EXTENSIONS,
  });

  useEffect(() => {
    const selectFile = async () => {
      if (!plainFiles || !plainFiles[0]) return;

      const extension = `.${plainFiles[0].name.split(".").pop()}`.toLowerCase();
      const isAudioOrVideo =
        plainFiles[0].type.toLowerCase().includes("audio") ||
        plainFiles[0].type.toLowerCase().includes("video");

      if (!SUPPORTED_FILE_EXTENSIONS.includes(extension) && !isAudioOrVideo) {
        datadogLogs.logger.info("Unsupported file", {
          deviceId: useUserSlice.getState().deviceId,
          name: plainFiles[0].name,
        });

        return useUISlice.getState().setAlertModal({
          title: t("landingPage.unSupportedFile"),
          subtitle: t("landingPage.unSupportedFileMessage", {
            fileName: plainFiles[0].name,
          }),
          button: {
            text: t("landingPage.ok"),
            onPress: () => useUISlice.getState().setAlertModal(undefined),
          },
        });
      }

      datadogLogs.logger.info("Funnel 2: Import file", {
        deviceId: useUserSlice.getState().deviceId,
        language: useUserSlice.getState().transcriptionOptions?.languageCode,
        fileSize: plainFiles[0].size,
      });

      useUISlice.getState().setAlertStartTranscription(plainFiles[0]);
    };

    selectFile();
  }, [plainFiles]);

  return null;
  return (
    <View>
      <Header />

      <View
        style={{
          flex: 1,
          justifyContent: "center",
          maxWidth: 620,
          width: "100%",
          alignSelf: "center",
        }}
      >
        <Text
          style={{
            ...s.headerTitle,
            ...(isMobile && { fontSize: 48, lineHeight: 48, marginTop: 24 }),
          }}
        >
          Write your notes{"\n"}
          <Text style={{ fontStyle: "italic" }}>faster</Text> than ever.
        </Text>

        <View
          style={{
            marginTop: 16,
            ...(isMobile && { paddingHorizontal: 16 }),
          }}
        >
          <FeatureItem number="1.">
            <Text style={{ fontSize: 18, color: Colors.text }}>
              <Text style={{ fontWeight: "500" }}>Upload</Text> your{" "}
              <Text
                onPress={() => _onLandingPageContentPress("recordings")}
                style={{ textDecorationLine: "underline" }}
              >
                recordings
              </Text>{" "}
              from meetings, lectures, brainstorms, anything.
            </Text>
          </FeatureItem>

          <FeatureItem number="2.">
            <Text style={{ fontSize: 18, color: Colors.text }}>
              <Text style={{ fontWeight: "500" }}>Review</Text> and improve your{" "}
              <Text
                onPress={() => _onLandingPageContentPress("high-quality")}
                style={{ textDecorationLine: "underline" }}
              >
                high-quality
              </Text>{" "}
              generated transcript.
            </Text>
          </FeatureItem>

          <FeatureItem number="3.">
            <Text style={{ fontSize: 18, color: Colors.text }}>
              <Text style={{ fontWeight: "500" }}>Generate</Text> summary,
              meeting notes and more using your recordings.
            </Text>
          </FeatureItem>

          <FeatureItem number="4.">
            <Text style={{ fontSize: 18, color: Colors.text }}>
              <Text style={{ fontWeight: "500" }}>Secure & Anonymous</Text> -
              all your data is{" "}
              <Text
                onPress={() => _onLandingPageContentPress("delete")}
                style={{ textDecorationLine: "underline" }}
              >
                deleted
              </Text>{" "}
              after 24 hours.
            </Text>
          </FeatureItem>

          <FeatureItem number="5.">
            <Text style={{ fontSize: 18, color: Colors.text }}>
              <Text style={{ fontWeight: "500" }}>It's free!</Text> Enjoy a
              30-minute free upload each day, forever.
            </Text>
          </FeatureItem>

          <FeatureItem number="6.">
            <Text style={{ fontSize: 18, color: Colors.text }}>
              Want more?{" "}
              <Text
                onPress={() => {}}
                style={{
                  textDecorationLine: "underline",
                  fontWeight: "500",
                  color: Colors.accent,
                }}
              >
                Upgrade to Premium
              </Text>
            </Text>
          </FeatureItem>
        </View>

        <View
          style={{ marginTop: 48, flexDirection: "row", alignSelf: "center" }}
        >
          <PrimaryButton
            onPress={openFilePicker}
            icon="upload"
            iconStyle={{ height: 24, width: 24, tintColor: Colors.accent }}
            style={{
              backgroundColor: Colors.hover,
              borderRadius: 48,
              height: 64,
              width: 240,
            }}
            textStyle={{ fontSize: 20, color: Colors.accent }}
            text="Upload a file"
          />
        </View>
      </View>
    </View>
  );
};

const s = StyleSheet.create({
  featureItem: {
    marginTop: 16,
    flexDirection: "row",
    gap: 12,
    alignItems: "flex-start",
  },
  headerTitle: {
    fontSize: 72,
    lineHeight: 70,
    textAlign: "center",
    fontFamily: instrumentSerif.style.fontFamily,
  },
});

export default LandingPage;
