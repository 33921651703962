import React from "react";
import { View, Pressable } from "@/ui";
import { Colors } from "@/constants";
import { useRouter } from "next/router";

const Logo: React.FC = () => {
  const router = useRouter();

  return (
    <Pressable
      onPress={() => {
        router.push("/");
      }}
      style={{
        flexDirection: "row",
        gap: 4,
        backgroundColor: Colors.accent,
        height: 32,
        width: 48,
        borderRadius: 8,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <View
        style={{
          width: 8,
          height: 12,
          backgroundColor: Colors.white,
          borderRadius: 12,
        }}
      />
      <View
        style={{
          width: 8,
          height: 8,
          backgroundColor: Colors.white,
          borderRadius: 12,
        }}
      />
    </Pressable>
  );
};

export default Logo;
